import store from "@/store";

let has_sent_datalayer = false;

export default {
    response: [
        /**
         * Checks if the server app version is equal to our current version
         */
        (res, err = null) => {
            if (err) {
                return res;
            }
            let server_version = res.headers["x-app-version"] || null;
            let current_version = process.env.VUE_APP_VERSION || null;

            if (!server_version || !current_version) {
                return res;
            }

            if (!has_sent_datalayer) {
                window.dataLayer?.push({
                    event: "version",
                    value: current_version,
                });

                has_sent_datalayer = true;
            }

            store.commit("updater/setServerVersion", server_version);

            if (server_version !== current_version) {
                console.warn(
                    `Server version ${server_version} is different from current version ${current_version}`
                );

                // Display modal
                store.commit("updater/setIsOutOfDate", true);
            }

            return res;
        },
    ],
};
